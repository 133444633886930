.App {
  text-align: center;
  background-color: #f5f5f5;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.App-header {
  background-color: white;
  color: white;
  padding: 20px;
}

.Sidebar {
  background-color: white;
  color: white;
  width: 5%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0; /* Change 'right' to 'left' */
  transition: 0.3s;
  overflow-x: hidden;
  padding-top: 60px;
  z-index: 1;
}

.Sidebar-open {
  width: 250px;
  z-index: 1;
}

.Main-content {
  flex: 1;
  overflow: auto;
  padding: 20px;
  margin-left: 250px; /* Add this line to make space for the open sidebar */
}

.Sidebar-toggle {
  margin-top: -5px;
  background-color: white;
  color: white;
  border: none;
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: 10px; /* Change 'right' to 'left' */
  z-index: 2;
}

h2{
  font-family: 'Montserrat', sans-serif;
  margin-top: 0px;
  font-size: 40px;
}

.body {
  font-family: 'Montserrat', sans-serif;
}

.login-container {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.left-section {
  flex: 1;
  position: relative;
}

.background-image {
  width: 90%;
  height: 100%;
  object-fit: cover;
}

.right-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 20px;
}

.logo {
  width: 200px; /* Adjust the logo size as needed */
  margin-bottom: 10%;
  margin-top: -20%;
}

.Header-logo{
  width: 200px;
  height: 70px;
  float: right;
  margin-right: -15%;
  margin-left: 10%;
}
.Header-logo1{
  width: 250px;
  height: 70px;
  float: right;
  margin-left: 20%;
}

.login-form {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
}

form {
  display: flex;
  flex-direction: column;
}

.form-group {

  margin-bottom: 15px;
}

label {
  font-weight: bold;
}

input {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 16px;
  width: 350px;
}

button {
  background-color: #739E17; /* Customize button color */
  color: white;
  padding: 10px 10px;
  border: none;
  border-radius: 20px; /* Rounded corners */
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  margin-left: 30%;
  transition: background-color 0.3s ease-in-out;
  width: 150px;
}

button:hover {
  background-color: #608412; /* Customize button hover color */
}

.Sidebar-toggle{
  background-color: transparent;
  margin-left: 0%;
  width: 40px;
  height: 40px;
  color: black;
}

.Sidebar-toggle:hover{
  background-color: transparent;
  width: 44px;
  height: 44px;
}

.mainheaderopen{
  font-size: 30px;
  position: relative;
  text-align: left;
  z-index: 2;
  transition: margin-left 0.3 ease-in-out;
}

.Clickable-item-text{
  margin-left: 10px;
  font-size: 18px;
  color: black;
}

.Clickable-images-and-text {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-bottom: 10px;
}

.Clickable-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px; /* Increased margin to space out the items */
}

.mainheaderclosed{
  font-size: 30px;
  position: absolute;
  text-align: left;
  margin-left: -10%;
  z-index: 2;
  transition: margin-left 0.3 ease-in-out;
}

.Bubble-area {
  margin: 10px;
  display: inline-block;
}

.Bubble {
  margin-top: 10%;
  margin-left: -10%;
  background-color: white;
  color: black;
  border-radius: 30%;
  width: 500px;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.Metric-name {
  font-size: medium;
  position: absolute; /* Position metric-name absolutely within the bubble */
  top: 30px; /* Adjust the top position to your preference */
  left: 0; /* Center metric-name horizontally within the bubble */
  right: 0; /* Center metric-name horizontally within the bubble */
  text-align: center; /* Center-align the text */
  width: 100%; /* Ensure metric-name takes the full width */
}

.Metric-value {
  font-size: 50px;
}
